.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.body{
  margin:auto;
  max-width: 450px;
  width: 100%;
  background-color: red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#listenViewImage::before{
  content: '';
  background: inherit;
  filter: blur(30px);
  position: absolute;
  top: -100px;
  left: -100px;
  right: -100px;
  bottom: -100px;
  z-index: -1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
